<template>
  <div class="equipment">
    <PageTopNav
      :tabsShow3="true"
      @changeTab3="changeTab3"
      :tabList3="tabList3"
      isAi
      :changeIndex3="changeIndex3"
    >
    </PageTopNav>
    <router-view></router-view>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { getEquipmentGroupApi } from "@/request/api";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
  },
  data() {
    return {
      tabList3: [],
      changeIndex3: 999,
    };
  },
  created() {
    this.getEquipmentGroupApi(this.$route.query.id);
  },

  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name"]),
    deepMergeFun(obj1, obj2) {
      let _this = this;
      for (let key in obj2) {
        obj1[key] =
          obj1[key] && obj1[key].toString() === "[object Object]"
            ? _this.deepMerge(obj1[key], obj2[key])
            : (obj1[key] = obj2[key]);
      }
      return obj1;
    },
    async getEquipmentGroupApi(id) {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200) {
        this.tabList3 = data;
        if (!sessionStorage.getItem("equipmentName")) {
          sessionStorage.setItem("equipmentId", data[0].id);
          sessionStorage.setItem("equipmentName", data[0].icon);
        }
        this.changeIndex3 = this.$route.query.id || 999;
      }
      if (!id) {
        this.changeTab3({ id: "", icon: "" });
      }
    },
    changeTab3({ id, icon }) {
      if (id) {
        this.changeIndex3 = id;
      }
      sessionStorage.setItem("childTitle3", this.changeIndex3);
      sessionStorage.setItem("equipmentId", id);
      sessionStorage.setItem("equipmentName", icon);
      this.SET_EQUIPMENT_Name({ icon });
      switch (icon) {
        case "weather":
          this.$router.push({
            name: "meteorological",
            query: { id },
          });
          break;

        case "video":
          this.$router.push({
            name: "gather",
            query: { id },
          });
          break;
        case "water":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "soil":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "heat":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "fertilizer":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "feed":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "control":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "machine":
          // this.$router.push({
          //   name: "equipmentmachine",
          // });
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "insect":
          // this.$router.push({
          //   name: "equipmentinsect",
          // });
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "gate":
          // this.$router.push({
          //   name: "equipmentgate",
          // });
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "grow":
          // sessionStorage.setItem("childTitle3", "");
          // sessionStorage.setItem("equipmentId", "");
          // sessionStorage.setItem("equipmentName", "");
          // this.$router.push({
          //   name: "equipmentgrow",
          // });
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "shuifei":
          this.$router.push({
            name: "chemical",
            // query: { id },
          });
          break;
        case "":
          // this.$router.push({
          //   name: "gather",
          //   query: { id: this.changeIndex3 },
          // });
          this.$router.push({
            name: "equipmentAi",
          });
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.equipment {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 0 0;
  color: #fff;
  overflow: hidden;
}
</style>
